import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { Label, Row, Col } from "reactstrap";
import Select from "react-select/creatable";
import ValidationMessage from "src/Components/Form/ValidationMessage";
import __ from "src/utils/Translations";
import { defaultStyles, errorStyles } from "./consts";
import { findSelectedOption } from "./utils";

export default function AutocompleteCreatable({
  value,
  label,
  onChange,
  id,
  errorMessage,
  validateField,
  validation,
  options,
  tooltip,
  inputSwitcher,
  disabled,
  placeholder,
  closeMenuOnSelect,
  classNamePrefix,
}) {
  const [customOptions, setCustomOptions] = useState([]);

  const resOptions = useMemo(
    () => [...options, ...customOptions],
    [customOptions, options]
  );

  const handleCreateOption = useCallback(
    (inputValue) => {
      const newOption = {
        value: inputValue,
        label: inputValue,
      };

      setCustomOptions((prevOptions) => {
        const updatedOptions = [...prevOptions, newOption];
        return updatedOptions;
      });

      onChange(newOption);
      validateField(id, newOption.value, validation);
    },
    [id, validation, setCustomOptions, onChange, validateField]
  );

  const selectValue = useMemo(
    () =>
      resOptions.reduce(
        (result, option) => findSelectedOption(result, option, value),
        null
      ),
    [resOptions, value]
  );

  const inputGroup = (
    <div
      className="input-group-omb font-weight-normal text-decoration-none"
      data-t1={id}
    >
      <Select
        isClearable
        className="omb-autocomplete-creatable font-weight-normal text-decoration-none"
        id={id}
        classNamePrefix={classNamePrefix}
        hideSelectedOptions={false}
        noOptionsMessage={() => __("Brak opcji")}
        formatCreateLabel={(inputValue) => `${__("Dodaj")} "${inputValue}"`}
        value={selectValue}
        menuPlacement="auto"
        isDisabled={disabled}
        placeholder={placeholder || __("Wybierz...")}
        options={resOptions}
        styles={errorMessage ? errorStyles : defaultStyles}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(option) => {
          onChange(option);
          validateField(id, option?.value, validation);
        }}
        onBlur={() => {
          if (validateField) {
            validateField(id, value, validation);
          }
        }}
        closeMenuOnSelect={closeMenuOnSelect}
        onCreateOption={handleCreateOption}
      />
      <ValidationMessage message={errorMessage} />
    </div>
  );

  return (
    <>
      {label ? (
        <Label data-t1={`${id}Label`} for={id} className="mr-sm-2">
          {label}
          {tooltip ? <> {tooltip}</> : null}
        </Label>
      ) : null}
      {inputSwitcher ? (
        <Row>
          <Col sm={9}>{inputGroup}</Col>
          <Col sm={3}>{inputSwitcher}</Col>
        </Row>
      ) : (
        inputGroup
      )}
    </>
  );
}

AutocompleteCreatable.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  tooltip: PropTypes.node,
  inputSwitcher: PropTypes.node,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  classNamePrefix: PropTypes.string,
  validateField: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      isDisabled: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          isDisabled: PropTypes.bool,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  closeMenuOnSelect: PropTypes.bool,
};

AutocompleteCreatable.defaultProps = {
  id: "",
  label: "",
  value: "",
  errorMessage: "",
  inputSwitcher: null,
  classNamePrefix: null,
  closeMenuOnSelect: true,
  disabled: false,
  tooltip: null,
  placeholder: null,
  validateField: () => {},
  validation: [],
};
