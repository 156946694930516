import PropTypes from "prop-types";
import React from "react";
import OneTimeServiceEditInfoPopup from "./popups/oneTimeServiceEditInfoPopup";
import SendCodesToEmployeePopup from "./popups/sendCodesToEmployeePopup";

export const popupVariants = {
  oneTimeServiceEditInfo: "oneTimeServiceEditInfo",
  sendCodesToEmployee: "sendCodesToEmployee",
};

const popupComponents = {
  [popupVariants.oneTimeServiceEditInfo]: OneTimeServiceEditInfoPopup,
  [popupVariants.sendCodesToEmployee]: SendCodesToEmployeePopup,
};

export default function Popup({
  isOpen,
  close,
  variant,
  employeeEmails,
  employeeId,
}) {
  const PopupComponent = popupComponents[variant] || null;

  return (
    <PopupComponent
      isOpen={isOpen}
      close={close}
      employeeEmails={employeeEmails}
      employeeId={employeeId}
    />
  );
}

Popup.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  employeeEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  employeeId: PropTypes.string.isRequired,
};
