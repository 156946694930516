import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import Form from "src/Components/Form";
import Popup from "src/Components/Popup/popup";
import { CATALOG_MANAGEMENT_SERVICE, restApiRequest } from "src/utils/Api";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { dynamicNotification } from "src/utils/Notifications";
import { catalogManagementInactiveEmployeeCodesWrite } from "src/utils/RoleBasedSecurity/permissions";
import __ from "src/utils/Translations";

export default function SendCodesToEmployeePopup({
  isOpen,
  close,
  employeeEmails = [],
  employeeId,
}) {
  const [formData, setFormData] = useState({});
  const [isButtonsDisabled, setButtonsDisabled] = useState(false);

  const onChange = useCallback(
    (key, value) => {
      setFormData((data) => ({ ...data, [key]: value }));
    },
    [setFormData]
  );

  const onSubmit = useCallback(async () => {
    try {
      setButtonsDisabled(true);
      const params = { email: formData?.email, employeeId };
      await restApiRequest(
        CATALOG_MANAGEMENT_SERVICE,
        "/inactive-employee-codes",
        "POST",
        {
          body: params,
        },
        null
      );
      dynamicNotification(__("Wysłano kody do użytkownika"));
      close();
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/OneTiemServices/popups/sendCodesToEmployeePopup.js - 39"
      );
      dynamicNotification(
        e.message || __("Nie udało się wysłać kodów do użytkownika"),
        "error"
      );
    }

    setButtonsDisabled(false);
  }, [formData.email, employeeId, setButtonsDisabled, close]);

  const emails = useMemo(
    () =>
      employeeEmails?.map((email) => ({
        value: email,
        label: email,
      })),
    [employeeEmails]
  );

  return (
    <>
      <Popup
        id="SendCodesToEmployeePopup"
        isOpen={isOpen}
        toggle={() => close()}
        unmountOnClose
      >
        <Form
          id="sendCodesToEmployeeForm"
          data={formData}
          config={{
            isInPopup: true,
            togglePopup: () => close(),
            title: "Wyślij kody do użytkownika",
            onSubmit,
            buttons: [
              {
                text: "Wyślij",
                type: "submit",
                id: "sendCodesToEmployeeForm",
                permission: catalogManagementInactiveEmployeeCodesWrite,
                disabled: isButtonsDisabled,
              },
            ],
            defaultOnChange: onChange,
            noCards: true,
            formGroups: [
              {
                formElements: [
                  {
                    id: "email",
                    label:
                      "Wprowadź adres email użytkownika do którego mają być wysłane kody:",
                    type: "autocompleteCreatable",
                    validation: ["required", "email"],
                    options: emails,
                  },
                  {
                    id: "secondEmail",
                    label: "Powtórz adres email:",
                    type: "autocompleteCreatable",
                    validation: [
                      "required",
                      "email",
                      {
                        method: "mustBeEqual",
                        args: [
                          formData && formData.email,
                          "Adresy email nie są takie same",
                        ],
                      },
                    ],
                    options: emails,
                  },
                ],
              },
            ],
          }}
        />
      </Popup>
    </>
  );
}

SendCodesToEmployeePopup.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  employeeEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  employeeId: PropTypes.string.isRequired,
};
