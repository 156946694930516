/* eslint-disable no-underscore-dangle, react/destructuring-assignment, no-plusplus */
import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import DataTable from "src/Components/DataTable";
import DataLoading from "src/Components/Loading/dataLoading";
import { MAGENTO_ADMIN_SERVICE, restApiRequest } from "src/utils/Api";
import __ from "src/utils/Translations";
import FormTitle from "src/Components/Form/FormTitle";
import ActionColumn from "src/Components/DataTable/actionColumn";
import useProductSuppliers from "src/utils/hooks/suppliers/useProductSuppliers";
import {
  AsyncAutocompleteSelectFilter,
  DateFilter,
  dateFilterMethod,
  SelectFilter,
} from "src/Components/DataTable/filters";
import {
  getDateCell,
  mapValueFromOptions,
  priceColumn,
} from "src/Components/DataTable/commonCells";
import useCompanyPointsBanks from "src/utils/hooks/pointsBank/useCompanyPointsBanks";
import priceFormatter from "src/utils/jsHelpers/priceFormatter";
import { getSuppliersOptionsFetchMethod } from "src/Components/FormElements/Autocomplete/commonFetchMethods";
import { getListingData } from "src/Components/DataTableControlled/utils";
import ButtonsList from "src/Components/ButtonsList";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import { catalogManagementInactiveEmployeeCodesWrite } from "src/utils/RoleBasedSecurity/permissions";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import { dynamicNotification } from "src/utils/Notifications";
import PreviewItemPopup from "./previewItemPopup";
import Popup, { popupVariants } from "./popup";

const TOTAL_ITEMS_LIMIT = 300;

export default function OneTimeService({
  employeeId,
  companyId,
  isEmployeeActive,
  employeeEmails,
}) {
  const [tableData, setTableData] = useState([]);
  const [paymentMethodsMap, setPaymentMethodsMap] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [alreadyFetched, setAlreadyFetched] = useState(false);
  const [previewItem, setPreviewItem] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupVariant, setPopupVariant] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [isItemsLoading, setIsItemsLoading] = useState(false);
  const [pagesNumberToRequest, setPagesNumberToRequest] = useState(0);
  const [hasCodes, setHasCodes] = useState(false);
  const closeForm = useCallback(() => {
    setOpenPopup(false);
    setPopupVariant(null);
  }, [setOpenPopup, setPopupVariant]);
  const suppliersOptions = useProductSuppliers(true, true);
  const isSendCodesToUserEnabled = useHasPermission(
    catalogManagementInactiveEmployeeCodesWrite
  );

  const pointsBanks = useCompanyPointsBanks(true, companyId, !companyId);

  const verifyItemsNumber = (result) => {
    const totalItems = result["hydra:totalItems"];
    if (totalItems > TOTAL_ITEMS_LIMIT) {
      setPagesNumberToRequest(Math.ceil(totalItems / TOTAL_ITEMS_LIMIT));
    }
  };

  useEffect(() => {
    if (!pagesNumberToRequest) {
      return;
    }

    setIsItemsLoading(true);

    const promises = [];
    for (let page = 2; page <= pagesNumberToRequest; page++) {
      promises.push(fetchData(page, TOTAL_ITEMS_LIMIT));
    }
    Promise.all(promises)
      .then((res) => {
        parseTableData(res.flat(), setTableData, setAlreadyFetched);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsItemsLoading(false);
      });
  }, [pagesNumberToRequest, setTableData, setAlreadyFetched]);

  useEffect(() => {
    setPaymentMethods([...pointsBanks, ...staticMethods]);
  }, [pointsBanks]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isEmployeeActive) {
          setHasCodes(false);
          return;
        }

        const { hasCodes } = (await restApiRequest(
          MAGENTO_ADMIN_SERVICE,
          `/get-mass-codes-data/${employeeId}/has-codes`,
          "GET",
          {},
          []
        )) || { hasCodes: false };

        setHasCodes(hasCodes);
      } catch (e) {
        console.error(e);
        newRelicErrorReport(
          e,
          "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/OneTimeServices/index.js - 115"
        );
        dynamicNotification(e.message || __("Błąd pobierania danych"), "error");
      }
    };

    fetchData();
  }, [employeeId, isEmployeeActive]);

  useEffect(() => {
    const newPaymentMethodsMap = {};
    paymentMethods.forEach(({ value, label }) => {
      newPaymentMethodsMap[value] = label;
    });
    setPaymentMethodsMap(newPaymentMethodsMap);
  }, [paymentMethods]);

  const fetchData = useCallback(
    async (page, pageSize) => {
      const { data: newData } = await getListingData(
        MAGENTO_ADMIN_SERVICE,
        "/user-orders",
        [{ id: "customerBusinessId", value: employeeId }],
        page,
        pageSize,
        null,
        {}
      );
      return newData;
    },
    [employeeId]
  );

  const buttons = useMemo(() => {
    const defaultButtons = [
      {
        size: "lg",
        color: "primary",
        className: "mr-2",
        text: "Warunki edycji świadczeń jednorazowych",
        data1: "subscription-edit-rules",
        onClick: () => {
          setOpenPopup(true);
          setPopupVariant(popupVariants.oneTimeServiceEditInfo);
        },
      },
    ];

    if (isSendCodesToUserEnabled) {
      defaultButtons.push({
        size: "lg",
        color: "primary",
        className: "mr-2",
        text: "Wyślij kody do użytkownika",
        disabled: !hasCodes || isEmployeeActive,
        onClick: () => {
          setOpenPopup(true);
          setPopupVariant(popupVariants.sendCodesToEmployee);
        },
      });
    }

    return defaultButtons;
  }, [isSendCodesToUserEnabled, isEmployeeActive, hasCodes, setOpenPopup]);

  return (
    <Card>
      <FormTitle
        title="Warunki edycji świadczeń jednorazowych"
        buttons={<ButtonsList buttons={buttons} />}
      />
      <CardBody>
        <DataLoading
          service={MAGENTO_ADMIN_SERVICE}
          endpoint="/user-orders"
          options={{
            headers: {
              accept: "application/ld+json",
            },
            params: {
              customerBusinessId: employeeId,
              page: 1,
              itemsPerPage: TOTAL_ITEMS_LIMIT,
            },
          }}
          fetchedData={alreadyFetched}
          updateData={(response) => {
            verifyItemsNumber(response);
            parseTableData(
              response["hydra:member"],
              setTableData,
              setAlreadyFetched
            );
          }}
          mockDataEndpoint="/employee/oneTimeServices/list"
        >
          <DataTable
            id="oneTimeServicesListing"
            key={`one_time_services_listing_${alreadyFetched}`}
            data={tableData}
            noCards
            filterable
            isFiltersLoading={isItemsLoading}
            columns={[
              {
                Header: "SKU",
                accessor: "sku",
              },
              {
                Header: "Dostawca",
                accessor: "supplierId",
                Filter: AsyncAutocompleteSelectFilter((filterValue) =>
                  getSuppliersOptionsFetchMethod(filterValue)
                ),
                Cell: mapValueFromOptions(suppliersOptions, "supplierId"),
                sortable: false,
              },
              {
                Header: "Nazwa świadczenia",
                accessor: "name",
              },
              {
                Header: "Ogólny koszt świadczenia",
                accessor: "rowTotal",
                Cell: priceColumn,
              },
              {
                Header: "Sposób opłacenia",
                accessor: "payments",
                Filter: SelectFilter(paymentMethods),
                filterMethod: filterBanksItems,
                Cell: (rowData) =>
                  displayBanksItems(rowData.row._original, paymentMethodsMap),
              },
              {
                Header: "ID płatności Online",
                accessor: "paymentsOnline",
                Cell: (rowData) =>
                  displayPaymentMethodOnline(rowData.row._original),
              },
              {
                Header: "Zamówienie",
                accessor: "orderId",
              },
              {
                Header: "ID",
                accessor: "businessId",
              },
              {
                Header: "Data ostatniej płatności",
                accessor: "lastPaymentDate",
                Filter: DateFilter(),
                filterMethod: dateFilterMethod,
                Cell: getDateCell("lastPaymentDate"),
              },
              {
                Header: "Akcja",
                filterable: false,
                sortable: false,
                accessor: "action",
                Cell: (rowData) => (
                  <div className="d-block w-100 text-center">
                    <ActionColumn
                      data={rowData.row._original}
                      buttons={[
                        {
                          id: "cancelSubscription",
                          onClick: () => {},
                          label: "Anuluj",
                          disabled: true,
                        },
                        {
                          id: "showDetails",
                          onClick: () => {
                            setItemId(rowData?.original?.itemId);
                            setPreviewItem(rowData.row._original);
                          },
                          label: "Podgląd wartości z dnia zakupu",
                        },
                      ]}
                    />
                  </div>
                ),
              },
            ]}
          />
        </DataLoading>
        {openPopup ? (
          <Popup
            close={closeForm}
            isOpen={Boolean(openPopup)}
            variant={popupVariant}
            employeeEmails={employeeEmails}
            employeeId={employeeId}
          />
        ) : null}
        {previewItem ? (
          <PreviewItemPopup
            close={() => setPreviewItem(null)}
            previewItem={previewItem}
            employeeId={employeeId}
            itemId={itemId}
          />
        ) : null}
      </CardBody>
    </Card>
  );
}

const parseTableData = (response, setTableData, setAlreadyFetched) => {
  let items = [];

  response.forEach(({ items: orderItems, id: orderId, orderType }) => {
    items = [
      ...items,
      ...orderItems.map((item) => {
        let lastPaymentDate = null;
        const paymentOnlineIds = [];
        const { productId, productCodeBusinessId } = item;
        let businessId = `${productId}-${productCodeBusinessId}`;
        if (!productId) {
          businessId = `${productCodeBusinessId}`;
        }
        const payments = item?.paymentMethod?.reduce(
          (
            result,
            {
              transaction_id: transactionId,
              external_transaction_id: externalTransactionId,
              transaction_status: transactionStatus,
              created_at: createdAt,
              values,
            }
          ) => {
            const onlineTransactionId =
              externalTransactionId || transactionId || "";
            const paymentDate = new Date(createdAt);
            if (!lastPaymentDate || paymentDate > lastPaymentDate) {
              lastPaymentDate = paymentDate;
            }
            if (values.find(({ type }) => type === "ONLINE_PAYMENT")) {
              paymentOnlineIds.push(onlineTransactionId);
            }
            const statusValues = values.map((v) => ({
              ...v,
              transactionStatus,
              onlineTransactionId,
            }));
            result.push(...statusValues);

            return result;
          },
          []
        );
        const paymentsOnline = paymentOnlineIds.join();

        return {
          ...item,
          productCodeBusinessId,
          businessId,
          payments,
          paymentsOnline,
          lastPaymentDate,
          orderType,
        };
      }),
    ];
  });

  setTableData((prevData) => {
    if (items.length > 0) {
      return [...prevData, ...items].sort(
        (a, b) => new Date(b.lastPaymentDate) - new Date(a.lastPaymentDate)
      );
    }
    return prevData;
  });

  setAlreadyFetched(true);
};

const staticMethods = [
  { value: "payu_gateway", label: "Płatność online - PayU" },
  { value: "payu_gateway_blik", label: "Płatność online - Blik" },
];
const transactionStatusColors = {
  new: "text-secondary",
  success: "text-success",
  rolled_back: "text-danger",
  roll_back_error: "text-danger",
  to_cancel: "text-danger",
  canceled: "text-danger",
  error: "text-danger",
  rejected: "text-danger",
  error_respond: "text-danger",
};

const filterBanksItems = (filter, row) =>
  row[filter.id]?.find(({ code }) => code === filter.value);

const displayBanksItems = (data, paymentMethodsMap) => {
  const { payments } = data;
  if (!payments) {
    return null;
  }

  return (
    <ul>
      {payments.map(({ code, value }) => {
        const serviceName = paymentMethodsMap[code] || "Nieznany bank";
        const formattedPrice = priceFormatter(value);

        return (
          <li key={code} data-t1={serviceName} data-t2={formattedPrice}>
            {serviceName}: {formattedPrice}
          </li>
        );
      })}
    </ul>
  );
};

const displayPaymentMethodOnline = (data) => {
  const { payments } = data;
  if (!payments) {
    return null;
  }

  const onlinePayments = payments.filter(
    ({ type }) => type === "ONLINE_PAYMENT"
  );
  if (!onlinePayments) {
    return null;
  }

  return (
    <ul>
      {onlinePayments.map(({ onlineTransactionId, transactionStatus }) => (
        <li key={onlineTransactionId}>
          {transactionStatusColors[transactionStatus] ? (
            <span className={`${transactionStatusColors[transactionStatus]}`}>
              {onlineTransactionId}
            </span>
          ) : (
            onlineTransactionId
          )}
        </li>
      ))}
    </ul>
  );
};

OneTimeService.propTypes = {
  employeeId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  isEmployeeActive: PropTypes.bool.isRequired,
  employeeEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
};
