/* eslint-disable no-restricted-syntax */
const findSelectedOption = (result, option, value) => {
  if (Array.isArray(option.options)) {
    for (const subOption of option.options) {
      const found = findSelectedOption(null, subOption, value);
      if (found) return found;
    }
  }

  return result || (option.value === value ? option : result);
};

export default findSelectedOption;
